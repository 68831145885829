import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import withoutAuth from "../../services/withoutAuthProvider";
import withAuth from "../../services/withAuthProvider";
import { useDispatch } from "react-redux";
import { actionsAuth } from "../../redux/auth.slice";
import toast from "react-hot-toast";
import OtpInput from "react-otp-input";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const initialData = {
  email: "",
  password: "",
  remember_me: false,
};

const SignIn = () => {
  const { email } = useParams();

  const [data, setData] = useState({ ...initialData });
  const [otp, setOtp] = useState("");
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledResend, setIsDisabledResend] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null);
  const { push } = useHistory();
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const otpExpired = localStorage.getItem("otpExpired");
    const realOtpExpired = otpExpired - Date.now();

    if (!otpExpired || realOtpExpired <= 0) {
      setIsLoadingSend(true);

      const dataBody = {
        email: email,
      };
      const response = await withoutAuth.postDataWithoutAuth(
        "/api/otp/send",
        dataBody
      );
      if (response) {
        setIsLoadingSend(false);
      }
      if (response?.status !== 200) {
        toast.error(response.data.message, {
          id: "error-message",
          duration: 3000,
        });
      } else {
        setTimeLeft(120);
        localStorage.setItem("otpExpired", response.data?.data?.expired_at);
      }
    } else {
      setIsLoadingSend(false);
      setTimeLeft(120);
    }
  }, []);

  useEffect(() => {
    if(otp && otp.length === 6) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [otp]);

  useEffect(() => {
    if (timeLeft === 0) {
      setIsDisabledResend(false);
    }

    if(timeLeft !== 0 & timeLeft !== null) {
      setIsDisabledResend(true);
      const interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000); // Update every second

      return () => clearInterval(interval);
    }
  }, [timeLeft]);

  const handleResend = async () => {
    setIsLoadingSend(true);

    const dataBody = {
      email: email,
    };

    const response = await withoutAuth.postDataWithoutAuth(
      "/api/otp/resend",
      dataBody
    );

    if(response) {
      setIsLoadingSend(false);
    }

    if (response?.status !== 200) {
      toast.error(response.data.message, {
        id: "error-message",
        duration: 3000,
      });
    } else {
      toast.success(response.data.message, {
        id: "success-message",
        duration: 3000,
      });

      // console.log(response.data?.data?.expired_at_original);
      localStorage.setItem("otpExpired", response.data?.data?.expired_at);
      
      // eslint-disable-next-line no-console
      setOtp("");
      setTimeLeft(120);
      setIsDisabledResend(false);
    }
  }

  const formatTime = (ms) => {
    const minutes = Math.floor(ms / 60);
    const seconds = ms % 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleSubmit = async () => {
    setIsLoadingSend(true);

    const dataVerify = {
      email: email,
      otp: otp
    };

    const response = await withoutAuth.postDataWithoutAuth(
      "/api/otp/verify",
      dataVerify
    );
    if (response) {
      setIsLoadingSend(false);

      if (response?.status === 200) {
        //Set token to withAuthProvider
        withAuth.setToken(response.data.data.accessToken);

        const responseUserData = await withAuth.getDataWithAuth(
          "/api/account/user",
          {}
        );
        if (responseUserData?.status === 200) {
          if (data?.remember_me)
            localStorage.setItem("remember_me", JSON.stringify(data.email));
          else localStorage.removeItem("remember_me");

          dispatch(
            actionsAuth.setUser({
              token: response.data.data.accessToken,
              user: responseUserData.data.data,
            })
          );
        }
      } else {
        if (
          typeof response.data.message === "object" &&
          response.data.message !== null &&
          !Array.isArray(response.data.message)
        ) {
          var data_message = "";
          for (var key in response.data.message) {
            if (response.data.message.hasOwnProperty(key)) {
              data_message += response.data.message[key];
            }
          }
          toast.error(data_message, {
            id: "error-message",
            duration: 3000,
          });
        } else {
          if (
            response?.status === 403 &&
            response?.data?.message ===
              "Demi perlindungan akun Anda, silakan perbarui kata sandi Anda"
          ) {
            push("/lupa-password");
          }

          if (
            response?.status === 400 &&
            response.data.message === "Email belum terverifikasi"
          ) {
            push("/verifikasi-akun", {
              dataParams: {
                email: data.email,
              },
            });
          } else {
            toast.error(response.data.message, {
              id: "error-message",
              duration: 3000,
            });
          }
        }
      }
    } else {
      toast.error("Gagal koneksi ke server", {
        id: "error-message",
        duration: 3000,
      });
    }
  };

  return (
    <div className="w-100">
      <p className="fw-bold fs-4 mb-4">Verifikasi OTP</p>
      <div
        className="font-sm d-flex mb-4"
        style={{ justifyContent: "space-between" }}
      >
        <div className="me-2 text-secondary">
          Masukkan kode OTP Verifikasi yang telah dikirim ke alamat email{" "}
          <b>{email}</b>
        </div>
      </div>
      <div className="w-100">
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span style={{ width: "100px" }}></span>}
          renderInput={(props) => <input {...props} />}
          inputType="number"
          inputStyle={{
            width: "100%",
            height: "50px",
            borderRadius: "10px",
            border: "1px solid gray",
          }}
        />

        <div className="d-grid gap-2 mt-4 mb-4">
          <button
            className="btn btn-primary font-sm"
            type="button"
            onClick={handleSubmit}
            disabled={isDisabled || isLoadingSend}
          >
            Verifikasi OTP
          </button>
        </div>

        {isDisabledResend ? (
          <div
            className="font-sm d-flex mb-4"
            style={{ justifyContent: "center" }}
          >
            <div className="me-2 text-secondary">Kirim ulang kode setelah</div>
            <div className="text-primary">{formatTime(timeLeft)}</div>
          </div>
        ) : (
          <div
            className="font-sm d-flex mb-3"
            style={{ justifyContent: "center" }}
          >
            <div className="me-2 text-secondary">Belum menerima OTP Code?</div>
            <div className="text-primary cursor-pointer" onClick={handleResend}>
              Resend
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignIn;
