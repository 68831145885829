import { useEffect, useRef, useState, React } from "react";
import { Col, Container, Row } from "react-bootstrap";
import withoutAuth from "../../services/withoutAuthProvider";
import toast from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { SkeletonJadwal } from "../../components/Skeleton/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { actionsPage, pageSelector } from "../../redux/page.slice";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import InfiniteScroll from "react-infinite-scroll-component";
import Time from "../../assets/icons/Time";
import Location from "../../assets/icons/Location";
import { id } from "date-fns/esm/locale";
import ArrowRight from "../../assets/icons/ArrowRight";
import { subdomainSelector } from "../../redux/subdomain.slice";

const initialPaginate = {
  search: "",
  page: 1,
  total: 0,
  page_size: 54,
};

const toastError = (message) => {
  return toast.error(message, {
    id: "error-message",
    duration: 3000,
  });
};

export default function LiveLelang() {
  const pageNumber = useSelector(pageSelector);
  const subdomain = useSelector(subdomainSelector)
  const dispatch = useDispatch();
  const today = format(new Date(), "yyyy");
  const { push, location } = useHistory();
  const dataPrev = location?.state?.dataFilter;
  const firstLoad = useRef(true);
  const [data, setData] = useState([]);
  const [paginate, setPaginate] = useState({ ...initialPaginate });
  const [loading, setLoading] = useState(true);
  let queryParams = new URLSearchParams(location?.search);

  const [pageSelected, setPageSelected] = useState(pageNumber);
  const [jumptoPage, setJumptoPage] = useState();

  const { search: searchParams } = useLocation();
  const parameters = new URLSearchParams(searchParams);
  const auction_id = parameters.get("auction_id");
  const handlePageClick = (event) => {
    dispatch(actionsPage.setPage({ page: event.selected }));
    setPageSelected(event.selected);

    // Update nilai input
    const inputElement = document.getElementById("pageInput");
    if (inputElement) {
      inputElement.value = event.selected + 1;
      setJumptoPage(event.selected + 1);
    }

    window.scrollTo({ top: 0 });
  };

  const years = [
    {
      id: 1,
      name: `${today - 3} - ${today}`,
    },
    {
      id: 2,
      name: `${today - 7} - ${today - 4}`,
    },
    {
      id: 3,
      name: `${today - 11} - ${today - 8}`,
    },
    {
      id: 4,
      name: `${today - 15} - ${today - 12}`,
    },
    {
      id: 5,
      name: `Dibawah ${today - 16}`,
    },
  ];
  const silinders = [
    {
      id: 1,
      name: "Kurang dari 1000 cc",
    },
    {
      id: 2,
      name: "1001 cc - 1300 cc",
    },
    {
      id: 3,
      name: "1301 cc - 1600 cc",
    },
    {
      id: 4,
      name: "1601 cc - 2000 cc",
    },
    {
      id: 5,
      name: "Lebih dari 2000 cc",
    },
  ];

  // filtering state
  let iniitialFilterValue = {
    unit_maker_id: dataPrev?.unit_maker_id || "",
    unit_model_id: dataPrev?.unit_model_id || "",
    unit_model_type_id: dataPrev?.unit_model_type_id || "",
    unit_type_id: dataPrev?.unit_type_id || "",
    year: dataPrev?.year || "",
    location_id: dataPrev?.location_id || "",
    unit_segment_id: dataPrev?.unit_segment_id || "",
    year_start: "",
    year_end: "",
    engine_capacity_start: "",
    engine_capacity_end: "",
    auction_id: auction_id || dataPrev?.auction_id || "",
    grade: dataPrev?.grade || "",
    start_auction_date: format(new Date(), "yyyy-MM-dd"),
    sort_order: dataPrev?.sort_order || "",
    tag_id: dataPrev?.tag_id || "",
    start_base_price: dataPrev?.start_base_price || "",
    end_base_price: dataPrev?.end_base_price || "",
  };
  const [search, setSearch] = useState(dataPrev?.search || "");
  const [filterValue, setFilterValue] = useState({ ...iniitialFilterValue });
  const [filterTag, setFilterTag] = useState([]);

  const validationSchema = Yup.object().shape({
    page: Yup.number(),
  });

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const resetFilter = () => {
    setFilterTag([]);
    setFilterValue({
      search: "",
      unit_maker_id: "",
      unit_model_id: "",
      unit_model_type_id: "",
      unit_type_id: "",
      year: "",
      location_id: "",
      unit_segment_id: "",
      auction_id: "",
      year_start: "",
      year_end: "",
      engine_capacity_start: "",
      engine_capacity_end: "",
      grade: "",
      start_auction_date: "",
      sort_order: "",
      tag_id: "",
      start_base_price: "",
      end_base_price: "",
    });
  };

  const removeTag = (idx) => {
    const foundData = filterTag.find((a, b) => b === idx);
    const name = foundData.name;
    setFilterValue((prev) => ({
      ...prev,
      [name]: "",
    }));
    setFilterTag((prev) => prev.filter((el) => el.name !== name));
  };

  const generateYear = (id) => {
    let final = {};
    if (id !== "") {
      const foundYear = years.find((a) => a.id === parseInt(id));
      const this_year = foundYear?.name?.split(" ");
      if (foundYear.id === 5) {
        final.year_end = this_year[1];
      } else {
        final.year_start = this_year[0];
        final.year_end = this_year[2];
      }
    }
    return final;
  };

  const generateSilinder = (id) => {
    const foundCC = silinders.find((a) => a.id === parseInt(id));
    let final = {};
    if (foundCC) {
      const this_cc = foundCC.name.split(" ");
      if (foundCC.id === 5 || foundCC.id === 1) {
        final.engine_capacity_start = this_cc[2];
      } else {
        final.engine_capacity_start = this_cc[0];
        final.engine_capacity_end = this_cc[3];
      }
      return final;
    }
  };

  const getDataList = async (params) => {
    setLoading(true);
    let newParams = {
      page_size: dataPrev?.isTimeBid ? 12 : 54,
    }
    if (subdomain !== 'global') {
      newParams.exclusive_seller_slug = subdomain
    }
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/auction?status=STARTED,PENDING",
      {
        //   ...filterValue,
        //   ...paginate,
        //   ...params,
        ...newParams
      }
    );
    if (response?.status === 200) {
      const dat = response?.data.data;

      await setData((prevData) => {
        if (dataPrev?.isTimeBid) {
          return [...prevData, ...dat.data];
        } else {
          return dat.data;
        }
      });

      await setPaginate((prev) => ({
        ...prev,
        page: dat.current_page + 1,
        total: dat.last_page,
        current_page: dat.current_page,
      }));

      dispatch(actionsPage.setPage({ page: dat?.current_page - 1 }));
    } else {
      toastError(response?.data.message);
    }
    setLoading(false);
  };
  const convertQueryParams = () => {
    if (location?.search != "") {
      const searchParams = {};
      for (const [key, value] of queryParams) {
        if (key != "page_size" && value != "null") {
          searchParams[key] = value;
        }
      }

      setFilterValue((data) => {
        return {
          ...data,
          ...searchParams,
        };
      });
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    setPageSelected(Number(jumptoPage - 1));
  };

  useEffect(() => {
    convertQueryParams();
  }, []);

  useEffect(() => {
    if (!firstLoad.current) {
      const params = {};
      Object.keys(filterValue).forEach((key) => {
        if (filterValue[key] !== "") {
          if (key === "year") {
            const final = generateYear(filterValue[key]);
            Object.keys(final).forEach((b) => {
              params[b] = final[b];
            });
          } else if (key === "engine_capacity") {
            const final = generateSilinder(filterValue[key]);
            Object.keys(final).forEach((c) => {
              params[c] = final[c];
            });
          } else {
            params[key] = filterValue[key];
          }
        }
      });
      getDataList(params);
    }
  }, [filterValue]);

  useEffect(() => {
    let params = {
      page: pageSelected + 1,
      page_size: paginate.page_size,
      // unit_type_id: filterValue.unit_type_id
    };
    if (auction_id) {
      params.auction_id = auction_id;
    }
    if (search !== "") {
      params.search = search;
    }
    if (filterValue.unit_type_id !== "") {
      params.unit_type_id = filterValue.unit_type_id;
    }
    if (dataPrev !== undefined) {
      Object.keys(filterValue).forEach((key) => {
        if (filterValue[key] !== "") {
          params[key] = filterValue[key];
        }
      });
    }
    getDataList(params);
    firstLoad.current = false;
  }, [pageSelected]);

  return (
    <div>
      <Container>
        <div className="d-flex justify-content-end"></div>
        {filterTag.length !== 0 ? (
          <Row>
            <Col span={12}>
              <div className="tag-filter-unit">
                <div
                  className="d-inline cursor-pointer"
                  onClick={resetFilter}
                  style={{
                    textDecoration: "none",
                    fontWeight: "bold",
                    padding: "8px",
                  }}
                >
                  <span>Reset</span>
                </div>
                {filterTag.map((filter, idx) => (
                  <span className="tag" key={`filter-${idx}`}>
                    {filter?.label}
                    <span
                      className="cursor-pointer"
                      onClick={() => removeTag(idx)}
                    >
                      <svg
                        style={{ marginLeft: "8px" }}
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.99999 4.05734L8.29999 0.757339L9.24266 1.70001L5.94266 5.00001L9.24266 8.30001L8.29999 9.24267L4.99999 5.94267L1.69999 9.24267L0.757324 8.30001L4.05732 5.00001L0.757324 1.70001L1.69999 0.757339L4.99999 4.05734Z"
                          fill="#9AA2B1"
                        />
                      </svg>
                    </span>
                  </span>
                ))}
              </div>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <InfiniteScroll
          dataLength={data.length}
          next={getDataList}
          hasMore={
            dataPrev?.isTimeBid && paginate.current_page < paginate.total
          }
          loader={<p>Loading...</p>}
          className="row mt-3"
          style={{ overflow: "hidden" }}
        >
          {loading ? (
            new Array(12).fill(1).map((el, indx) => (
              <div className="col-6 col-md-2 mb-4" key={`unit-${indx}`}>
                <SkeletonJadwal />
              </div>
            ))
          ) : data.length > 0 ? (
            <>
              {data.map((item, idx) => {

                const year = item?.unit_informations?.find(
                  (unit_information) => unit_information.label === "Tahun"
                );

                const odometer = item?.unit_informations?.find(
                  (unit_information) => unit_information.label === "Odometer"
                );

                const transmisi = item?.unit_informations?.find(
                  (unit_information) => unit_information.label === "Transmisi"
                );

                const fuel = item?.unit_informations?.find(
                  (unit_information) => unit_information.label === "Bahan Bakar"
                );

                const validityPeriod = item?.unit_documents?.find(
                  (unit_document) => unit_document.label === "STNK"
                );

                const unitDetail = {
                  police_number: item?.police_number,
                  year: year?.value,
                  odometer: odometer?.value,
                  transmisi: transmisi?.value,
                  fuel: fuel?.value,
                  validityPeriod: validityPeriod?.additional_value,
                };

                return (
                  <div className="col-6 col-md-2 mb-4" key={`unit-live-${idx}`}>
                    <div
                      className={`card-unit-jadwal h-100 cursor-pointer`}
                      onClick={() => {
                        if (item?.auction_category === "TIME-BID") {
                          dispatch(actionsPage.setPage({ page: 0 }));
                          push("/unit-lelang", {
                            dataFilter: {
                              auction_id: item?.id,
                              unit_type_id: item?.unit_type?.id,
                              isTimeBid: item?.auction_category === "TIME-BID",
                            },
                          });
                        } else {
                          push("/live-auction/" + item?.id);
                        }
                      }}
                    >
                      <div className="card-unit-jadwal-header overflow-hidden">
                        <img
                          className="card-unit-jadwal-image"
                          src={item?.unit_type?.banner_url}
                          alt="..."
                          loading="lazy"
                        />
                      </div>

                      <div className="card-unit-jadwal-body d-flex flex-column">
                        <div
                          style={{
                            height: "50px",
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <h5 className="card-unit-jadwal-title">
                            {item?.name || "-"}
                          </h5>
                        </div>
                        <div className="card-unit-jadwal-date">
                          {format(new Date(item?.date_start), "dd MMM yyyy", {
                            locale: id,
                          })}
                        </div>
                        <div className="card-unit-jadwal-time d-flex">
                          <Time />
                          <span>{`${item?.time_start?.substr(
                            0,
                            5
                          )} - ${item?.time_finish?.substr(0, 5)} WIB`}</span>
                        </div>
                        <div className="card-unit-jadwal-location d-flex">
                          <Location />
                          <span> {item?.location?.name || "-"}</span>
                        </div>
                        <div
                          className="card-unit-jadwal-footer mt-4"
                          style={{}}
                        >
                          <span
                            className="cursor-pointer"
                            style={{
                              display: "table - cell",
                              verticalAlign: "middle",
                              marginRight: "10px",
                            }}
                            onClick={() => {
                              if (item?.auction_category === "TIME-BID") {
                                dispatch(actionsPage.setPage({ page: 0 }));
                                push("/unit-lelang", {
                                  dataFilter: {
                                    auction_id: item?.id,
                                    unit_type_id: item?.unit_type?.id,
                                    isTimeBid: item?.auction_category === "TIME-BID",
                                  },
                                });
                              } else {
                                push("/live-auction/" + item?.id);
                              }
                            }}
                          >
                            Lihat Detail
                          </span>{" "}
                          <ArrowRight />
                        </div>
                      </div>
                    </div>
                    {/* <CardUnitJadwal
                      id={item?.id}
                      judul={item?.name || "-"}
                      tanggal={item?.date_start}
                      waktu={`${item?.time_start?.substr(
                        0,
                        5
                      )} - ${item?.time_finish?.substr(0, 5)} WIB`}
                      lokasi={item?.location?.name || "-"}
                      // live={item?.is_started}
                      onClick={() => {
                        push("/live-auction/" + item?.id);
                      }}
                      unit_type_image={item?.unit_type?.banner_url}
                    /> */}
                  </div>
                );
              })}
            </>
          ) : (
            <div className="text-center text-secondary mt-5">
              Unit lelang tidak ditemukan
            </div>
          )}
        </InfiniteScroll>
      </Container>
    </div>
  );
}
