import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CardUnitJadwal from "../../components/CardUnit/CardUnitJadwal";
import FilterJadwalLelang from "../../components/Filters/FilterJadwalLelang";
import DatePicker from "react-datepicker";
// import subDays from "date-fns/subDays";
// import addDays from "date-fns/addDays";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/component.css";
import withoutAuth from "../../services/withoutAuthProvider";
import { format } from "date-fns";
import { el } from "date-fns/locale";
import MoreButton from "../../components/MoreButton/MoreButton";
import { useHistory } from "react-router-dom";
import { SkeletonJadwal } from "../../components/Skeleton/Skeleton";
import { actionsPage } from "../../redux/page.slice";
import { useDispatch, useSelector } from "react-redux";
import { subdomainSelector } from "../../redux/subdomain.slice";

const initialPaginate = {
  page: 1,
  page_size: 12,
  total: 1,
  location_id: "",
  unit_type_id: "",
};

const CloseButton = () => (
  <svg
    style={{ marginLeft: "8px" }}
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99999 4.05734L8.29999 0.757339L9.24266 1.70001L5.94266 5.00001L9.24266 8.30001L8.29999 9.24267L4.99999 5.94267L1.69999 9.24267L0.757324 8.30001L4.05732 5.00001L0.757324 1.70001L1.69999 0.757339L4.99999 4.05734Z"
      fill="#9AA2B1"
    />
  </svg>
);

export default function JadwalLelangMendatang() {
  const { push } = useHistory();
  const [units, setUnits] = useState([]);
  const dispatch = useDispatch();
  const [paginate, setPaginate] = useState({ ...initialPaginate });
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [filters, setFilters] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const subdomain = useSelector(subdomainSelector)

  const resetFilter = () => {
    setPaginate({ ...initialPaginate });
    setFilters([]);
    setStartDate(new Date());
  };

  const removeFilter = (nameId) => {
    setFilters((prev) => prev.filter((el) => el.id !== nameId));
    if (nameId === "start_date") {
      setStartDate(new Date());
    }
  };

  const loadMore = () => {
    setPaginate((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const removeTag = (nameId) => {
    setPaginate((prev) => ({
      ...prev,
      [nameId]: "",
    }));
    removeFilter(nameId);
  };

  const setFilterTag = (nameId, newData = {}) => {
    const foundFilter = filters.find((filt) => filt.id === nameId);
    if (foundFilter) {
      setFilters((prev) =>
        prev.map((data) => ({
          ...data,
          name: data.id === nameId ? newData.name : data.name,
        }))
      );
    } else {
      setFilters((prev) => [
        ...prev,
        {
          id: nameId,
          name: newData.name,
        },
      ]);
    }
  };

  const changeDate = (date) => {
    const newData = {
      id: "start_date",
      name: format(date, "dd MMM yyyy"),
    };
    setStartDate(date);
    setFilterTag("start_date", newData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaginate((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (value !== "") {
      const myArr = name === "location_id" ? [...locations] : [...unitTypes];
      const foundData = myArr.find((el) => el.id === parseInt(value));
      setFilterTag(name, foundData);
    } else {
      removeFilter(name);
    }
  };

  const getLocation = async () => {
    const params = {};
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/location",
      params
    );
    if (response?.status === 200) {
      // console.log(response.data.data.data);
      setLocations(response.data.data.data);
    } else {
      // console.log(response.response);
    }
  };
  const getUnitTypes = async () => {
    const params = {};
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/unit-type",
      params
    );
    if (response?.status === 200) {
      // console.log(response.data.data.data);
      setUnitTypes(response.data.data.data);
    } else {
      // console.log(response.response);
    }
  };

  const getDataJadwal = async () => {
    setIsLoading(true);
    const date = format(startDate, "yyyy-MM-dd");
    const found = filters.find((el) => el.id === "start_date");
    const params = {
      page: 1,
      page_size: paginate.page_size,
      start_date: date,
      // end_date: date,
      status: 'COMING',
    };
    if (found) {
      params.start_date = date;
      params.end_date = date;
    }
    if (paginate.location_id !== "") {
      params.location_id = paginate.location_id;
    }
    if (paginate.unit_type_id !== "") {
      params.unit_type_id = paginate.unit_type_id;
    }
    if (subdomain !== 'global') {
      params.exclusive_seller_slug = subdomain
    }
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/auction",
      params
    );
    if (response?.status === 200) {
      const dat = response.data.data;
      // console.log(dat)
      await setUnits(dat.data);
      await setPaginate((prev) => ({
        ...prev,
        total: dat.last_page,
      }));
      setIsLoading(false);
      // } else {
      //     toastError(response.data.message)
    }
    // setLoading(false);
  };

  const fetchMoreData = async () => {
    const date = format(startDate, "yyyy-MM-dd");
    const found = filters.find((el) => el.id === "start_date");
    const params = {
      page: paginate.page,
      page_size: paginate.page_size,
      start_date: date,
      // end_date: date,
    };
    if (filters.find((el) => el.id === "start_date")) {
      params.start_date = el.name;
      params.start_date = el.name;
    }
    if (found) {
      params.start_date = date;
      params.end_date = date;
    }

    if (paginate.location_id !== "") {
      params.location_id = paginate.location_id;
    }
    if (paginate.unit_type_id !== "") {
      params.unit_type_id = paginate.unit_type_id;
    }
    const response = await withoutAuth.getDataWithoutAuth(
      "/api/auction",
      params
    );
    if (response?.status === 200) {
      const dat = response.data.data;
      await setUnits((prev) => [...prev, ...dat.data]);
      // } else {
      //     toastError(response.data.message)
    }
  };

  useEffect(() => {
    getLocation();
    getUnitTypes();
  }, []);

  useEffect(() => {
    if (paginate.page !== 1) {
      fetchMoreData();
    }
  }, [paginate.page]);

  useEffect(() => {
    getDataJadwal();
    if (paginate.page !== 1) {
      setPaginate((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [paginate.location_id, paginate.unit_type_id, startDate]);

  return (
    <div className="jadwalLelang">
      <div>
        <Container>
          <Row>
            <Col md={{ span: 4 }}>
              <div className="w-100 d-flex justify-content-center">
                <DatePicker
                  selected={startDate}
                  // highlightDates={highlightWithRanges}
                  onChange={(date) => changeDate(date)}
                  inline
                />
              </div>
            </Col>
            <Col md={{ span: 8 }}>
              <FilterJadwalLelang
                data={paginate}
                locations={locations}
                unitTypes={unitTypes}
                handleChange={handleChange}
              />
              <div
                className={filters.length > 0 ? "tag-filter-jadwal" : "d-none"}
              >
                <span
                  className="fw-bold text-primary cursor-pointer disable-copy"
                  onClick={resetFilter}
                  style={{ padding: "8px" }}
                >
                  Hapus Semua
                </span>
                {filters.map((filter, idx) => (
                  <span className="tag disable-copy" key={idx}>
                    {filter.name}
                    <span
                      className="cursor-pointer"
                      onClick={() => removeTag(filter.id)}
                    >
                      <CloseButton />
                    </span>
                  </span>
                ))}
              </div>
              <div className="row" style={{ marginBottom: "16px" }}>
                {isLoading ? (
                  new Array(8).fill(1).map((el, idx) => (
                    <div
                      className="col-6 col-md-3 mb-4"
                      key={`skeleton jadwal-${idx}`}
                    >
                      <SkeletonJadwal />
                    </div>
                  ))
                ) : units.length > 0 ? (
                  units.map((el, index) => (
                    <div className="col-md-3 col-6 mb-4" key={"b" + index}>
                      <CardUnitJadwal
                        id={el?.id}
                        judul={el?.name || "-"}
                        tanggal={el?.date_start}
                        waktu={`${el?.time_start?.substr(
                          0,
                          5
                        )} - ${el?.time_finish?.substr(0, 5)} WIB`}
                        lokasi={el?.location?.name || "-"}
                        live={el?.is_started}
                        onClick={() => {
                          if (el?.is_exclusive && subdomain === 'global') {
                            window.open(
                              "https://" +
                                el?.exclusive_seller_slug +
                                "." +
                                window.location.hostname +
                                "/unit-lelang"
                            );
                          } else {
                            dispatch(actionsPage.setPage({ page: 0 }));
                            push("/unit-lelang", {
                              dataFilter: {
                                auction_id: el?.id,
                                unit_type_id: el?.unit_type?.id,
                                isTimeBid: el?.auction_category === "TIME-BID",
                              },
                            });
                          }
                        }}
                        unit_type_image={el?.unit_type?.banner_url}
                      />
                    </div>
                  ))
                ) : (
                  <div className="text-secondary text-center mt-5">
                    Tidak ada jadwal lelang
                  </div>
                )}
              </div>
              {paginate.page !== paginate.total && (
                <MoreButton onClick={loadMore} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
