import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { PasswordField } from "../../components/PasswordField/PasswordField";
import withoutAuth from "../../services/withoutAuthProvider";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { validatePassword } from "../../utils/string";
import { Form, Spinner } from "react-bootstrap";
import toast from 'react-hot-toast';

const initialData = {
    password: '',
    password2: '',
}

const ResetPassword = () => {
    // const [data, setData] = useState({ ...initialData });
    const { token, email } = useParams();
    const { push } = useHistory();
    // const [message, setMessage] = useState(null);
    const [loadingPassword, setLoadingPassword] = useState(false);
    const [statusValidation, setStatusValidation] = useState(null);

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .transform(x => x === '' ? undefined : x)
            .concat(Yup.string().required('Silahkan mengisi kata sandi'))
            .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/, 'Kata Sandi harus memiliki minimal 8 karakter, terdapat huruf besar dan kecil serta mengandung angka')
            .min(8, 'Kata sandi harus minimal 8 karakter'),
        password_confirmation: Yup.string()
            .transform(x => x === '' ? undefined : x)
            .when('password', (password, schema) => {
                if (password) return schema.required('Silahkan mengisi konfirmasi kata sandi');
            })
            .oneOf([Yup.ref('password')], 'Kata sandi harus sama'),
    });

    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: { is_agree: false },
    });

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setData(prev => ({
    //         ...prev,
    //         [name]: value
    //     }))
    // }

    // const handleSubmit = async () => {
    //     const loading = toast.loading('Mengirimkan...');
    //     const dataReset = {
    //         token: token,
    //         email: email,
    //         password: data.password,
    //         password_confirmation: data.password2,
    //     }

    //     const response = await withoutAuth.postDataWithoutAuth('/api/password/reset', dataReset)
    //     if (response?.status === 200) {
    //         toast.dismiss(loading);
    //         toast.success(response.data.message, {
    //             id: 'success-message',
    //             duration: 3000
    //         });
    //         push("/masuk");
    //     } else {
    //         toast.dismiss(loading);
    //         if (typeof response.data.message === 'object' && response.data.message !== null && !Array.isArray(response.data.message)) {
    //             var data_message = "";
    //             for (var key in response.data.message) {
    //                 if (response.data.message.hasOwnProperty(key)) {
    //                     data_message += response.data.message[key]
    //                 }
    //             }
    //             toast.error(data_message, {
    //                 id: 'error-message',
    //                 duration: 3000
    //             });
    //         } else {
    //             toast.error(response.data.message, {
    //                 id: 'error-message',
    //                 duration: 3000
    //             });
    //         }
    //     }
    // }

    const onSubmit = async (data, e) => {
        e.preventDefault();

        const dataReset = {
            token: token,
            email: email,
            password: data.password,
            password_confirmation: data.password_confirmation,
        }

        setLoadingPassword(true);
        const loading = toast.loading('Mengirimkan...');
        const response = await withoutAuth.postDataWithoutAuth('/api/password/reset', dataReset)

        if (response?.status === 200) {
            toast.dismiss(loading);
            toast.success(response.data.message, {
                id: 'success-message',
                duration: 3000
            });
            setLoadingPassword(false);
            reset({
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
            })
            push("/masuk")
        } else {
            toast.dismiss(loading);
            if (typeof response.data.message === 'object' && response.data.message !== null && !Array.isArray(response.data.message)) {

                var data_message = "";
                for (var key in response.data.message) {
                    if (response.data.message.hasOwnProperty(key)) {
                        data_message += response.data.message[key]
                    }
                }

                toast.error(data_message, {
                    id: 'error-message',
                    duration: 3000
                });
            } else {
                toast.error(response.data.message, {
                    id: 'error-message',
                    duration: 3000
                });
            }

            setLoadingPassword(false);
        }

        e.target.reset();
    }

    useEffect(async () => {
        const response = await withoutAuth.getDataWithoutAuth(`/api/password/check/${token}/${email}`, {})
        if (response?.status !== 200) {
            toast.error(response.data.message, {
                id: 'error-message',
                duration: 3000
            });
        }
    }, []);

    return (
        <>
            <div className='w-100'>
                <p className="fw-bold fs-4 mb-2">Buat Kata Sandi Baru</p>
                {/* <div className="d-inline-block my-3 " style={{ width: '160px', height: '160px', backgroundColor: '#F0F2F5' }}>

                </div> */}
                <div className='font-sm d-flex mb-3'>
                    <div className='me-2 text-secondary'>
                        Kata sandi baru Anda haruslah berbeda dari kata sandi
                        <br />
                        Anda yang lama</div>
                </div>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mb-3'>
                        {/* <PasswordField
                            id='password'
                            name='password'
                            placeholder=''
                            onChange={handleChange}
                            label='Kata Sandi Baru'
                            value={data.password}
                            extended
                        /> */}
                        <Controller
                            control={control}
                            name="password"
                            render={({ field: { onChange, name, value } }) => (
                                <PasswordField
                                    validation={statusValidation}
                                    id='password'
                                    placeholder='Masukan Kata Sandi'
                                    onChange={(val) => {
                                        setStatusValidation(validatePassword(val?.target?.value))
                                        onChange(val)
                                    }}
                                    label='Kata Sandi Baru'
                                    name={name}
                                    value={value}
                                    extended
                                />
                            )}
                        />
                        {errors.password?.message && <span className='error-message'>{errors.password?.message}</span>}
                    </div>
                    <div className='mb-3'>
                        {/* <PasswordField
                            id='password2'
                            name='password2'
                            placeholder=''
                            onChange={handleChange}
                            label='Ulangi Kata Sandi Baru'
                            value={data.password2}
                        /> */}
                        <Controller
                            control={control}
                            name="password_confirmation"
                            render={({ field: { onChange, name, value } }) => (
                                <PasswordField
                                    id='password2'
                                    placeholder='Masukan Konfirmasi Kata Sandi'
                                    onChange={onChange}
                                    label='Ulangi Kata Sandi Baru'
                                    name={name}
                                    value={value}
                                />
                            )}
                        />
                        {errors.password_confirmation?.message && <span className='error-message'>{errors.password_confirmation?.message}</span>}
                    </div>
                    <div className="d-grid pt-3 gap-2">
                        <button className="btn btn-primary font-sm" type="submit" disabled={loadingPassword}>
                            {loadingPassword ? (<Spinner animation="border" size="sm" />) : ""}
                            Simpan
                        </button>
                    </div>
                </Form>
                
            </div >
        </>
    )
}

export default ResetPassword;