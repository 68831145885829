import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { authList } from './routing/authList';
import CustomRoute from './routing/CustomRoute/CustomRoute';
import { routeList } from './routing/routeList';
import { Provider, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './config/store'
import './App.css';
import '@mdi/font/css/materialdesignicons.css'
import ProtectedRoute from './routing/ProtectRoute/ProtectedRoute';
import toast, { Toaster } from 'react-hot-toast';
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { onMessageListener, requestPermission } from "./firebase";
import MetaPixel from "./utils/metaPixel";

const ToastDisplay = ({ title, body }) => {
  return (
    <div>
      <p><b>{title}</b></p>
      <p>{body}</p>
    </div>
  );
};

function App() {
  const [notification, setNotification] = useState({ title: '', body: '' });
  requestPermission().then(permission => console.log(`Notification permission : ${permission}`));
  const notify = (title, body) => toast(<ToastDisplay title={title} body={body} />);

  useEffect(() => {
    if (notification?.title) {
      notify(notification?.title, notification?.body)
    }
  }, [notification])

  onMessageListener()
    .then((payload) => {
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MetaPixel />
          <Router>
            <Switch>
              {
                authList.map((route, idx) => (
                  <ProtectedRoute
                    key={idx}
                    path={route.path}
                    // exact={route.exact}
                    layout={route.layout}
                    isLogged={route.isLogged}
                    page={route.component} />
                ))
              }
              {
                routeList.map((route, idx) => (
                  <CustomRoute
                    key={idx}
                    path={route.path}
                    // exact={route.exact}
                    layout={route.layout}
                    page={route.component} />
                ))
              }
            </Switch>
            <Toaster />
          </Router>
        </PersistGate>
      </Provider>
    </>
  );
}

export default Sentry.withProfiler(App);
