import React from "react";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import proxyBidImg from "../../assets/images/proxy-bid.png";

const CancelledProxy = ({ setCancelProxy }) => {
  return (
    <>
      {" "}
      <div className="com-modal-bg"></div>
      <div className="com-modal d-flex flex-center">
        <div className="com-modal-dialog">
          <div className="com-modal-content">
            <div className="winner-auction">
              <div className="winner-auction-body text-center">
                <div className="py-4">
                  <img src={proxyBidImg} alt="ProxyBidCancelled" />
                </div>
                <div className="cancelled-title">Data Proxy Bid Berubah</div>
                <div>
                  Terjadi perubahan data yang mengakibatkan Proxy Bid Anda{" "}
                  <br /> lepas, mohon untuk dilakukan submit ulang Proxy Bid.
                </div>
                <div className="mt-4">
                  <PrimaryButton
                    onClick={() =>
                      setCancelProxy((prev) => ({
                        ...prev,
                        isOpenModal: false,
                      }))
                    }
                  >
                    Selesai
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelledProxy;
