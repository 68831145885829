import HeaderTop from '../../components/Header/HeaderTop.js';
import HeaderBottom from '../../components/Header/HeaderBottom.js';
import HeaderMenu from '../../components/Header/HeaderMenu.js';
import withoutAuthProvider from "../../services/withoutAuthProvider.js";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { actionsSubdomain, subdomainSelector } from "../../redux/subdomain.slice.js";
import { useDispatch, useSelector } from 'react-redux';

export default function Headers() {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [exclusiveData, setExclusiveData] = useState(null)

    const subdomain = useSelector(subdomainSelector)
    const host = window.location.host;
    const hostSubdomain = host.split(".")[0];
    // const hostSubdomain = 'cnaf';
    const hostFinalSubdomain = hostSubdomain !== 'caready' && !hostSubdomain.includes('localhost') && hostSubdomain !== 'staging' ? hostSubdomain : 'global'

    if (hostFinalSubdomain !== subdomain) {
        dispatch(actionsSubdomain.setSubdomain({ subdomain: hostFinalSubdomain }))
    }

    const toastError = (message) => {
        return toast.error(message, {
            id: 'error-message',
            duration: 3000,
        });
    };

    const getExclusive = async () => {
        try {
            const response = await withoutAuthProvider.getDataWithoutAuth(`/api/auction/seller/${subdomain}`);
            if (response?.status === 200) {
                const dat = response?.data.data;
                setExclusiveData(dat)
            } else {
                toastError(response?.data.message);
            }
        } catch (error) {
            toastError(error?.data.message);
        }
    };

    useEffect(() => {
        if (subdomain !== 'global') {
            getExclusive()
        }
    }, [subdomain])

    return (
        <header className='position-fixed top-0 vw-100' style={{ zIndex: '5' }}>
            <HeaderTop />
            <HeaderBottom setIsOpen={setIsOpen} exclusiveData={exclusiveData}/>
            <HeaderMenu isOpen={isOpen} setIsOpen={setIsOpen} exclusiveData={exclusiveData}/>
        </header>
    )
}
