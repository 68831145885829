import { useEffect, useRef, useState, React } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import CardUnit from '../../components/CardUnit/CardUnit';
import FilterUnitLelang from '../../components/Filters/FilterUnitLelang';
import FilterTag from '../../components/Filters/FilterTag';
import withoutAuth from '../../services/withoutAuthProvider';
import toast from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { defaultBanner } from '../../utils/defaultValue';
import { SkeletonUnitLelang } from '../../components/Skeleton/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { actionsPage, pageSelector } from '../../redux/page.slice';
import ReactPaginate from 'react-paginate';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// import NumberFormat from "react-number-format";
import * as Yup from 'yup';
import InfiniteScroll from 'react-infinite-scroll-component';
import bg from '../../assets/images/bg-gradient.png';
import moment from 'moment';
import { subdomainSelector } from '../../redux/subdomain.slice';

const initialPaginate = {
  search: '',
  page: 1,
  total: 0,
  page_size: 30,
};

const initialBanner = {
  banner1: { image_url: '', link: '/' },
  banner2: { image_url: '', link: '/' },
};

const toastError = (message) => {
  return toast.error(message, {
    id: 'error-message',
    duration: 3000,
  });
};

export default function UnitLelang() {
  const pageNumber = useSelector(pageSelector);
  const dispatch = useDispatch();
  const subdomain = useSelector(subdomainSelector)
  const today = format(new Date(), 'yyyy');
  const { push, location } = useHistory();
  const dataPrev = location?.state?.dataFilter;
  const firstLoad = useRef(true);
  const [data, setData] = useState([]);
  const [paginate, setPaginate] = useState({ ...initialPaginate });
  const [loading, setLoading] = useState(true);
  const [banners, setBanners] = useState({ ...initialBanner });
  const [unitType, setUnitType] = useState([]);
  let queryParams = new URLSearchParams(location?.search);
  const [showRisalah, setShowRisalah] = useState(false);
  const [isRisalah, setRisalah] = useState(0);

  const [pageSelected, setPageSelected] = useState(pageNumber);
  const [jumptoPage, setJumptoPage] = useState();

  const { search: searchParams } = useLocation();
  const parameters = new URLSearchParams(searchParams);
  const auction_id = parameters.get('auction_id');
  // const newPage = event.selected;
  // dispatch(actionsPage.setPage({ page: newPage }));
  // setPageSelected(newPage);
  const handlePageClick = (event) => {
    dispatch(actionsPage.setPage({ page: event.selected }));
    setPageSelected(event.selected);

    // Update nilai input
    const inputElement = document.getElementById('pageInput');
    if (inputElement) {
      inputElement.value = event.selected + 1;
      setJumptoPage(event.selected + 1);
    }

    window.scrollTo({ top: 0 });
  };
  // const handlePageClick = (event) => {
  //   dispatch(actionsPage.setPage({ page: event.selected }));
  //   setPageSelected(event.selected);
  //   window.scrollTo({ top: 0 });
  // };

  const years = [
    {
      id: 1,
      name: `${today - 3} - ${today}`,
    },
    {
      id: 2,
      name: `${today - 7} - ${today - 4}`,
    },
    {
      id: 3,
      name: `${today - 11} - ${today - 8}`,
    },
    {
      id: 4,
      name: `${today - 15} - ${today - 12}`,
    },
    {
      id: 5,
      name: `Dibawah ${today - 16}`,
    },
  ];
  const silinders = [
    {
      id: 1,
      name: 'Kurang dari 1000 cc',
    },
    {
      id: 2,
      name: '1001 cc - 1300 cc',
    },
    {
      id: 3,
      name: '1301 cc - 1600 cc',
    },
    {
      id: 4,
      name: '1601 cc - 2000 cc',
    },
    {
      id: 5,
      name: 'Lebih dari 2000 cc',
    },
  ];

  // filtering state
  let iniitialFilterValue = {
    unit_maker_id: dataPrev?.unit_maker_id || '',
    unit_model_id: dataPrev?.unit_model_id || '',
    unit_model_type_id: dataPrev?.unit_model_type_id || '',
    unit_type_id: dataPrev?.unit_type_id || '',
    year: dataPrev?.year || '',
    location_id: dataPrev?.location_id || '',
    unit_segment_id: dataPrev?.unit_segment_id || '',
    year_start: '',
    year_end: '',
    engine_capacity_start: '',
    engine_capacity_end: '',
    auction_id: auction_id || dataPrev?.auction_id || '',
    grade: dataPrev?.grade || '',
    start_auction_date: dataPrev?.start_auction_date || '',
    sort_order: dataPrev?.sort_order || '',
    tag_id: dataPrev?.tag_id || '',
    start_base_price: dataPrev?.start_base_price || '',
    end_base_price: dataPrev?.end_base_price || '',
  };
  const [search, setSearch] = useState(dataPrev?.search || '');
  const [filterValue, setFilterValue] = useState({ ...iniitialFilterValue });
  const [filterTag, setFilterTag] = useState([]);

  const validationSchema = Yup.object().shape({
    page: Yup.number(),
  });

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const resetFilter = () => {
    setFilterTag([]);
    setFilterValue({
      search: '',
      unit_maker_id: '',
      unit_model_id: '',
      unit_model_type_id: '',
      unit_type_id: '',
      year: '',
      location_id: '',
      unit_segment_id: '',
      auction_id: '',
      year_start: '',
      year_end: '',
      engine_capacity_start: '',
      engine_capacity_end: '',
      grade: '',
      start_auction_date: '',
      sort_order: '',
      tag_id: '',
      start_base_price: '',
      end_base_price: '',
    });
  };

  const removeTag = (idx) => {
    const foundData = filterTag.find((a, b) => b === idx);
    const name = foundData.name;
    setFilterValue((prev) => ({
      ...prev,
      [name]: '',
    }));
    setFilterTag((prev) => prev.filter((el) => el.name !== name));
  };

  // const nextPage = () => {
  //   const current = pageNumber;
  //   if (!(current + 1 > paginate.total)) {
  //     dispatch(actionsPage.resetPage());
  //     setPaginate((prev) => ({
  //       ...prev,
  //       page: prev.page + 1,
  //     }));
  //   }
  // };

  // const prevPage = () => {
  //   const current = pageNumber;
  //   const val = current - 1;
  //   if (!(val < 0) && val !== 0) {
  //     // console.log('prevv')
  //     dispatch(actionsPage.setPage({ page: val }));
  //     setPaginate((prev) => ({
  //       ...prev,
  //       page: val,
  //     }));
  //   }
  // };

  const generateYear = (id) => {
    let final = {};
    if (id !== '') {
      const foundYear = years.find((a) => a.id === parseInt(id));
      const this_year = foundYear?.name?.split(' ');
      if (foundYear.id === 5) {
        final.year_end = this_year[1];
      } else {
        final.year_start = this_year[0];
        final.year_end = this_year[2];
      }
    }
    return final;
  };

  const generateSilinder = (id) => {
    const foundCC = silinders.find((a) => a.id === parseInt(id));
    let final = {};
    if (foundCC) {
      const this_cc = foundCC.name.split(' ');
      if (foundCC.id === 5 || foundCC.id === 1) {
        final.engine_capacity_start = this_cc[2];
      } else {
        final.engine_capacity_start = this_cc[0];
        final.engine_capacity_end = this_cc[3];
      }
      return final;
    }
  };

  const getBannerList = async () => {
    const params = {
      page: 1,
      page_size: 2,
      type: 'UNIT_LELANG',
    };
    const response = await withoutAuth.getDataWithoutAuth('/api/banner', params);
    if (response?.status === 200) {
      const dat = response.data.data.data;
      setBanners({
        banner1: {
          image_url: dat[0]?.file_url ?? defaultBanner,
          link: dat[0]?.link ?? '/',
        },
        banner2: {
          image_url: dat[1]?.file_url ?? defaultBanner,
          link: dat[1]?.link ?? '/',
        },
      });
    } else {
      toastError(response?.data.message);
    }
  };

  const getDataList = async (params) => {
    setLoading(true);
    try {
      let newParams = {
        ...filterValue,
        ...paginate,
        ...params,
        page_size: dataPrev?.isTimeBid ? 10 : 30,
      }
      if (subdomain !== 'global') {
        newParams.exclusive_seller_slug = subdomain
      }
      const response = await withoutAuth.getDataWithoutAuth('/api/unit', newParams);
      if (response?.status === 200) {
        const dat = response?.data.data;
        if (dat?.data?.length > 0 && params?.auction_id) {
          dat?.data[0]?.auction?.risalah_file_url ? setRisalah(1) : setRisalah(0);
        }
        setData((prevData) => {
          if (dataPrev?.isTimeBid) {
            return [...prevData, ...dat.data];
          } else {
            return dat.data;
          }
        });

        setPaginate((prev) => ({
          ...prev,
          page: dat.current_page + 1,
          total: dat.last_page,
          current_page: dat.current_page,
        }));

        dispatch(actionsPage.setPage({ page: dat?.current_page - 1 }));
      } else {
        toastError(response?.data.message);
      }
    } catch (error) {
      toastError(error?.data.message);
    }

    setLoading(false);
  };

  const handleSearch = async (id, tagId = null) => {
    const params = {
      search: search,
      unit_type_id: id,
      page: 1,
      page_size: 30,
      tag_id: tagId,
    };
    setFilterValue((prev) => ({
      ...prev,
      ...params,
    }));
    setValue('page', 1);
    setPageSelected(0);

    await setData([]);
    getDataList(params);
  };
  // const handleSearch = async (id, tagId = null) => {
  //   const params = {
  //     search: search,
  //     unit_type_id: id,
  //     page: 1,
  //     tag_id: tagId,
  //   };
  //   setFilterValue((prev) => ({
  //     ...prev,
  //     ...params,
  //   }));

  //   setPaginate(initialPaginate);
  //   await setData([]);
  //   getDataList(params);
  // };

  const convertQueryParams = () => {
    if (location?.search !== '') {
      const searchParams = {};
      for (const [key, value] of queryParams) {
        if (key !== 'page_size' && value !== 'null') {
          searchParams[key] = value;
        }
      }

      setFilterValue((data) => {
        return {
          ...data,
          ...searchParams,
        };
      });
    }
  };

  const getUnitType = async () => {
    const response = await withoutAuth.getDataWithoutAuth('/api/unit-type', {});
    if (response?.status === 200) {
      const dataUnitType = response?.data.data.data;
      setUnitType(dataUnitType);
    } else {
      toast.error(response?.data.message, {
        id: 'error-message',
        duration: 3000,
      });
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    setPageSelected(Number(jumptoPage - 1));
  };

  useEffect(() => {
    getBannerList();
    getUnitType();
    convertQueryParams();
  }, []);

  useEffect(() => {
    if (!firstLoad.current) {
      const params = {};
      Object.keys(filterValue).forEach((key) => {
        if (filterValue[key] !== '') {
          if (key === 'year') {
            const final = generateYear(filterValue[key]);
            Object.keys(final).forEach((b) => {
              params[b] = final[b];
            });
          } else if (key === 'engine_capacity') {
            const final = generateSilinder(filterValue[key]);
            Object.keys(final).forEach((c) => {
              params[c] = final[c];
            });
          } else {
            params[key] = filterValue[key];
          }
        }
      });
      getDataList(params);
    }
  }, [filterValue]);

  useEffect(() => {
    let params = {
      page: pageSelected + 1,
      page_size: paginate.page_size,
      // unit_type_id: filterValue.unit_type_id
    };
    if (auction_id) {
      params.auction_id = auction_id;
    }
    if (search !== '') {
      params.search = search;
    }
    if (filterValue.unit_type_id !== '') {
      params.unit_type_id = filterValue.unit_type_id;
    }
    if (dataPrev !== undefined) {
      Object.keys(filterValue).forEach((key) => {
        if (filterValue[key] !== '') {
          params[key] = filterValue[key];
        }
      });
    }
    getDataList(params);
    firstLoad.current = false;
  }, [pageSelected]);

  // console.log("filter", filterValue);
  // console.log("dataPrv", dataPrev?.unit_type_id);
  return (
    <div>
      <FilterUnitLelang
        years={years}
        silinders={silinders}
        search={search}
        setSearch={setSearch}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        filterTag={filterTag}
        setFilterTag={setFilterTag}
        handleSearch={handleSearch}
        removeTag={removeTag}
        unitType={unitType}
      />
      <FilterTag></FilterTag>
      <Container>
        <div className="d-flex justify-content-end"></div>
        {filterTag.length !== 0 ? (
          <Row>
            <Col span={12}>
              <div className="tag-filter-unit">
                <div
                  className="d-inline cursor-pointer"
                  onClick={resetFilter}
                  style={{
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    padding: '8px',
                  }}
                >
                  <span>Reset</span>
                </div>
                {filterTag.map((filter, idx) => (
                  <span className="tag" key={`filter ${idx}`}>
                    {filter?.label}
                    <span className="cursor-pointer" onClick={() => removeTag(idx)}>
                      <svg
                        style={{ marginLeft: '8px' }}
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.99999 4.05734L8.29999 0.757339L9.24266 1.70001L5.94266 5.00001L9.24266 8.30001L8.29999 9.24267L4.99999 5.94267L1.69999 9.24267L0.757324 8.30001L4.05732 5.00001L0.757324 1.70001L1.69999 0.757339L4.99999 4.05734Z"
                          fill="#9AA2B1"
                        />
                      </svg>
                    </span>
                  </span>
                ))}
              </div>
            </Col>
          </Row>
        ) : (
          ''
        )}
        <InfiniteScroll
          dataLength={data.length}
          next={getDataList}
          hasMore={dataPrev?.isTimeBid && paginate.current_page < paginate.total}
          loader={<p>Loading...</p>}
          className="row mt-3"
          style={{ overflow: 'hidden' }}
        >
          {loading ? (
            new Array(8).fill(1).map((el, indx) => (
              <div className="col-6 col-md-3 mb-4" key={`unit-${indx}`}>
                <SkeletonUnitLelang />
              </div>
            ))
          ) : data.length > 0 ? (
            <>
              {data.map((item, idx) => {
                const year = item?.unit_informations?.find((unit_information) => unit_information.label === 'Tahun');

                const odometer = item?.unit_informations?.find((unit_information) => unit_information.label === 'Odometer');

                const transmisi = item?.unit_informations?.find((unit_information) => unit_information.label === 'Transmisi');

                const fuel = item?.unit_informations?.find((unit_information) => unit_information.label === 'Bahan Bakar');

                const validityPeriod = item?.unit_documents?.find((unit_document) => unit_document.label === 'STNK');

                const unitDetail = {
                  police_number: item?.police_number,
                  year: year?.value,
                  odometer: odometer?.value,
                  transmisi: transmisi?.value,
                  fuel: fuel?.value,
                  validityPeriod: validityPeriod?.additional_value,
                };

                return (
                  <>
                    {isRisalah === 1 && idx === 0 && (
                      <div className="col-6 col-md-3 mb-4">
                        <div
                          className="card-unit h-100 cursor-pointer justify-content-center d-flex flex-column align-items-center pt-5"
                          style={{ backgroundImage: `url(${bg})` }}
                          onClick={() => setShowRisalah(true)}
                        >
                          <div className="mb-2 mt-5">
                            <span className="h1 text-white fw-bold">
                              RISALAH
                            </span>
                          </div>
                          <div className="bg-white px-2 py-1 rounded mb-5">
                            <span className="text-primary h-5">
                              {data[0]?.auction?.unit_type_name}
                            </span>
                          </div>
                          <div className="mt-5">
                            <span className="text-white fw-bold fs-5">
                              {data[0]?.auction?.location_name}
                            </span>
                          </div>
                          <div className="mt-1 d-flex">
                            <div>
                              <span className="text-white">
                                {moment(data[0]?.auction?.date_start).format(
                                  "DD MMM YYYY"
                                )}
                              </span>
                              <span className="text-white mx-1">&#x2022;</span>
                              <span className="text-white">
                                {moment(
                                  data[0]?.auction?.time_start,
                                  "HH:mm:ss"
                                ).format("HH:mm")}
                              </span>
                              <span className="text-white">-</span>
                              <span className="text-white">
                                {moment(
                                  data[0]?.auction?.time_finish,
                                  "HH:mm:ss"
                                ).format("HH:mm")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-6 col-md-3 mb-4" key={`unit - ${idx}`}>
                      <CardUnit
                        link={
                          item?.auction?.is_exclusive && subdomain === "global"
                            ? "https://" +
                              item?.auction?.exclusive_seller_slug +
                              "." +
                              window.location.hostname +
                              "/unit-lelang/" +
                              item.id
                            : "/unit-lelang/" + item.id
                        }
                        lot_number={
                          item?.lot_number && item?.auction?.auction_lane_name
                            ? item?.auction?.auction_lane_name +
                              item?.lot_number
                            : "-"
                        }
                        price={item?.base_price}
                        tags={[]}
                        nama={
                          item?.unit_name ||
                          item?.year +
                            " " +
                            item?.unit_maker?.name +
                            " " +
                            item?.unit_model?.name
                        }
                        location={item?.auction?.location_name}
                        auction={item?.auction}
                        pictures={item?.pictures}
                        onClick={() =>
                          item?.auction?.is_exclusive && subdomain === "global"
                            ? window.open(
                                "https://" +
                                  item?.auction?.exclusive_seller_slug +
                                  "." +
                                  window.location.hostname +
                                  "/unit-lelang/" +
                                  item.id
                              )
                            : push("/unit-lelang/" + item.id)
                        }
                        grade={item?.unit_inspection?.grade}
                        specialNIPL={item.number_of_nipl}
                        ppn_buyer_fee={item.ppn_buyer}
                        ppn_include={item.ppn_buyer_include}
                        unitType={item?.unit_type?.name}
                        unitDetail={unitDetail}
                        is_cancel_auction={item?.is_cancel_auction !== "0"}
                      />
                    </div>
                    {/* ini untuk web view */}
                    {(idx === 2 - isRisalah ||
                      idx === 6 - isRisalah ||
                      idx === 12 - isRisalah ||
                      idx === 16 - isRisalah ||
                      idx === 22 - isRisalah ||
                      (isRisalah === 1 && idx === 26 ? null : idx === 26) ||
                      idx === 32 - isRisalah ||
                      idx === 36 - isRisalah ||
                      idx === 42 - isRisalah ||
                      idx === 46 - isRisalah) && (
                      <div className="d-none d-md-block col-6 col-md-3 mb-4">
                        <div
                          className="card-unit h-100 overflow-hidden cursor-pointer"
                          onClick={() =>
                            window.open(
                              idx === 2 - isRisalah ||
                                idx === 12 - isRisalah ||
                                idx === 22 - isRisalah ||
                                idx === 32 - isRisalah ||
                                idx === 42 - isRisalah
                                ? banners?.banner1?.link
                                : banners?.banner2?.link,
                              "_blank"
                            )
                          }
                        >
                          <img
                            alt="banner"
                            className="h-100 w-100"
                            src={
                              idx === 2 - isRisalah ||
                              idx === 12 - isRisalah ||
                              idx === 22 - isRisalah ||
                              idx === 32 - isRisalah ||
                              idx === 42 - isRisalah
                                ? banners?.banner1?.image_url
                                : banners?.banner2?.image_url
                            }
                            // src={defaultBanner}
                            style={{
                              objectFit: "contain",
                              backgroundColor: "#cf1701",
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {/* ini untuk mobile view */}
                    {(idx === 0 || idx === 8) && (
                      <div className="d-block d-md-none col-6 col-md-3 mb-4">
                        <div
                          className="card-unit h-100 overflow-hidden cursor-pointer"
                          onClick={() =>
                            window.open(
                              idx === 2 - isRisalah
                                ? banners?.banner1?.link
                                : banners?.banner2?.link,
                              "_blank"
                            )
                          }
                        >
                          <img
                            alt="banner"
                            className="h-100 w-100"
                            src={
                              idx === 2 - isRisalah
                                ? banners?.banner1?.image_url
                                : banners?.banner2?.image_url
                            }
                            // src={defaultBanner}
                            style={{
                              objectFit: "contain",
                              backgroundColor: "#cf1701",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
              <Col md="12">
                {!dataPrev?.isTimeBid ? (
                  <div className="pagination">
                    {/* <div>Halaman:</div>
                                            <div><span className='fw-bold'>{pageNumber}</span> dari <span>{paginate.total}</span></div>
                                            <div>
                                                <ul>
                                                    <li>
                                                        <div className='cursor-pointer' onClick={prevPage}>
                                                            <span className="previous">
                                                                <Previous fill={"#FFFFFF"} />
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='cursor-pointer' onClick={nextPage}>
                                                            <span className="next">
                                                                <Next fill={"#FFFFFF"} />
                                                            </span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div> */}

                    <Col>Halaman :</Col>
                    <Col className="rowPagination">
                      <Form onSubmit={handleSubmit(onSubmit)} style={{}}>
                        <Form.Group className="form-group" controlId="formBasicEmail">
                          {/* <label htmlFor="pageInput"></label> */}
                          <Controller
                            control={control}
                            name="page"
                            render={({ field: { onChange, name, value } }) => (
                              <input
                                style={{ width: 50 }}
                                type="text"
                                className="form-control "
                                id="pageInput"
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  if (/^\d*$/.test(e.target.value) && e.target.value !== '0') {
                                    onChange(e.target.value);
                                    setJumptoPage(e.target.value);
                                  }
                                }}
                              // onChange={(e) =>
                              //   setJumptoPage(e.target.value)
                              // }
                              />
                            )}
                          />
                        </Form.Group>
                      </Form>
                    </Col>

                    <Col className="rowPagination ">dari {paginate.total}</Col>

                    <ReactPaginate
                      // breakLabel="..."
                      nextLabel={'>'}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={2}
                      pageCount={paginate.total}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                      initialPage={pageSelected}
                      disabledClassName="paginationUnitDisabled"
                      containerClassName="pagination-container-unit mt-3"
                      activeClassName="active"
                      pageClassName="paginationNone"
                      breakClassName="paginationNone"
                      nextClassName="paginationUnit-item next"
                      previousClassName="paginationUnit-item previous"
                    />
                  </div>
                ) : (
                  <></>
                )}
              </Col>
              <Modal
                show={showRisalah}
                onHide={() => setShowRisalah(false)}
                dialogClassName="modal-width-90"
                size="lg"
                aria-labelledby="risalah"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">Risalah</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="embed-responsive" style={{ height: '87vh' }}>
                    <iframe src={data[0]?.auction?.risalah_file_url} width="100%" height="100%" title="Risalah" />
                  </div>
                </Modal.Body>
              </Modal>
            </>
          ) : (
            <div className="text-center text-secondary mt-5">Unit lelang tidak ditemukan</div>
          )}
        </InfiniteScroll>
      </Container>
    </div>
  );
}
