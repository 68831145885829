import { useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DropdownLot } from "../HeaderDropdown/DropdownLot";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import ButtonExclusive from "../ButtonExclusive/ButtonExclusive.js";
import { subdomainSelector } from "../../redux/subdomain.slice.js";
// import useOutsideClick from '../../utils/useOutsideClick';

const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6869_28759)">
      <path
        d="M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z"
        fill="#1E1E1F"
      />
    </g>
    <defs>
      <clipPath id="clip0_6869_28759">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const HeaderMenu = ({ isOpen, setIsOpen, exclusiveData }) => {
  const subdomain = useSelector(subdomainSelector)
  const { push } = useHistory();
  const ref = useRef();
  const { pathname } = useLocation();
  //   useOutsideClick(ref, () => isOpen && setIsOpen(false));

  const handleClick = (path) => {
    push(path);
    setIsOpen(false);
  };

  const handleClickDropdownLot = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div
      ref={ref}
      onMouseDown={(e) => e.stopPropagation()}
      className={`${isOpen ? "d-block" : "d-none"
        } vh-100 w-75 bg-white position-absolute top-0 left-0`}
      style={{ zIndex: "7" }}
    >
      <div className="border-b border" style={{ padding: "1rem" }}>
        <div onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </div>
      </div>
      <div
        style={{ padding: "1rem 1.25rem", fontSize: "1rem", gap: "1rem" }}
        className="d-flex flex-column"
      >
        {/* {
          subdomain !== 'global' &&
          <ButtonExclusive isFullWidth/>
        } */}
        <div onClick={() => handleClick("/live-lelang")}>Live Lelang</div>
        <div onClick={() => handleClick("/unit-lelang")}>Unit Lelang</div>
        <div>Jadwal</div>
        <div
          onClick={() => handleClick("/jadwal-lelang-hari-ini")}
          style={{ marginLeft: "1.5rem" }}
        >
          Lelang Hari Ini
        </div>
        <div
          onClick={() => handleClick("/jadwal-lelang-mendatang")}
          style={{ marginLeft: "1.5rem" }}
        >
          Lelang Mendatang
        </div>
        <div onClick={() => handleClick("/titip-lelang")}>Titip Lelang</div>
        <div onClick={() => handleClick("/beli-nipl")}>Beli NIPL</div>
      </div>
      {pathname.includes("time-bid-auction") && (
        <div
          style={{ marginLeft: "3px", marginTop: "-5px", fontSize: "1rem" }}
          onMouseDown={(e) => handleClickDropdownLot(e)}
        >
          <DropdownLot
            title_slot={<div>Lot Time Bid</div>}
            styleDropdown={{
              width: "200px",
              maxHeight: "170px",
              overflowY: "auto",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default HeaderMenu;
