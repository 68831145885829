import { createSlice } from '@reduxjs/toolkit'
// Slice
const subdomainSlice = createSlice({
  name: 'subdomain',
  initialState: {
    subdomain: 'global',
  },
  reducers: {
    setSubdomain: (state, action) => {
      state.subdomain = action.payload.subdomain;
    }
  },
});

export const subdomainSelector = state => state.subdomainReducer.subdomain;

export const { actions: actionsSubdomain, reducer } = subdomainSlice;