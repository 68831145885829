import { useSelector } from "react-redux"
import { subdomainSelector } from "../../redux/subdomain.slice"
import UnitLelang from "../UnitLelang/UnitLelang"
import Home from "../Home"

const HomeNew = () => {
  const subdomain = useSelector(subdomainSelector)
  return (
    <>
      {
        subdomain !== 'global' ? <UnitLelang /> : <Home />
      }
    </>
  )
}

export default HomeNew